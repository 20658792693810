import noaccesspic from "../images/undraw_pedestrian_crossing_l-6-jv.svg";
export const NoAccess = () => {
  return (
    <div fxLayout="row" class="fullHeight centerAlign">
      <div fxFlex="20"></div>
      <div fxFlex="65" fxLayout="column">
        <div fxFlex="5"></div>
        <div fxFlex="10" class="blueFontColor">
          <span>
            {" "}
            Apologies for the inconvenience but it seems that you don't have the
            right access.
            <br />
            Please raise the required accesses on secure site or feel free to
            contact our team.
          </span>
        </div>
        <div fxFlex="5"></div>
        <div fxFlex="78">
          <img src={noaccesspic} />
        </div>
        <div fxFlex="2"></div>
      </div>
      <div fxFlex="20"></div>
    </div>
  );
};
