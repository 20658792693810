import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import reportWebVitals from "./reportWebVitals";
import { Toolkit } from "@uitk/react";
import { optum } from "@uitk/foundations";
import { BrowserRouter } from "react-router-dom";
import { RoutesApp } from "./routes";

const root = ReactDOM.createRoot(document.getElementById("root"));
// const express = require("express");
// const cors = require("cors");
// const app = express();

// app.use(
//   cors({
//     origin:"http://localhost:3002/",
//   })
// );
const appId = "OPTUM_APP_ID";
root.render(
  <React.StrictMode>
    <Toolkit appId={appId} theme={optum}>
      <BrowserRouter>
        <RoutesApp />
      </BrowserRouter>
    </Toolkit>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
