import axios from "axios";
import { config } from "../../config";
import { parseJwt, setCookie, getCookie } from "./helper1.js";

const checkAuthentication = async (): Promise<boolean> => {
  // check login status
  if (
    getCookie("authAccessTokenInfo") === "" ||
    getCookie("authAccessTokenInfo") === null
  ) {
    const token = new URL(window.location.href);
    const accessCode = new URLSearchParams(token.search).get("code");

    // check code is there in url or not
    if (!accessCode) {
      window.location.href = config.APP_SSO_LINK;
    }

    // check url present or not in window.location.href
    if (window.location.href) {
      // eslint-disable-next-line @typescript-eslint/no-shadow
      const token = new URL(window.location.href);
      // eslint-disable-next-line @typescript-eslint/no-shadow
      const accessCode = new URLSearchParams(token.search).get("code");

      // check code is there in url or not
      if (accessCode) {
        // eslint-disable-next-line @typescript-eslint/no-use-before-define
        const response = await getNewAccessToaken(accessCode);
        if (response?.data?.access_token !== undefined) {
          //clearing storage
          localStorage.clear();
          sessionStorage.clear();
          const userData = parseJwt(response?.data?.id_token);
          console.log("userData", userData);
          var minutesDiff = (userData.exp - userData.iat) / 60;
          setCookie(
            "authAccessTokenInfo",
            response?.data?.access_token + "|" + 1,
            minutesDiff
          ); //setting cookie
          setCookie("userInfo", JSON.stringify(userData), minutesDiff); //setting cookie
          //
          localStorage.setItem("userToken", response?.data?.access_token);

         window.history.pushState("", "", config.APP_BASEURL);
        }
      }
    }
  }
  return true;
};

// Main function for user authentication
export async function useAuth() {
  const isAuth = await checkAuthentication();
  return isAuth;
}

const getNewAccessToaken = async (accessCode: string) => {
  const config1 = {
    method: "post",
    url: config.Token_Api_URL,
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
    },
    data: {
      grant_type: "authorization_code",
      code: accessCode,
      redirect_uri: config.APP_BASEURL,
      client_id: config.client_id,
      client_secret: config.client_secret,
    },
  };
  return await axios(config1)
    .then((response) => {
      return response;
    })
    .catch((e) => {
      return { data: [] }; // return empty data to prevent promise rejection
    });
};
