import React, { useEffect } from "react";
import { getCookie } from "../../components/helper";
import { useNavigate } from "react-router-dom";
import "./loading.css";
import { useAuth } from "../AuthProvider/authentication";
import { Button, Card, LoadingIndicator, Heading } from "@uitk/react";

// eslint-disable-next-line react/prop-types
export const LoaderOverLay = ({ message }: { message: string }) => {
  return (
    <div>
      <LoadingIndicator
        loading={true}
        displayOverlay={true}
        size="l"
        loadingText={message}
      >
        <Card
          header={<Heading level="h2">Card Header</Heading>}
          subheader={<Heading level="h3">Sub Header</Heading>}
        ></Card>
      </LoadingIndicator>
    </div>
  );
};
