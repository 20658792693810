export const parseJwt = (token: any) => {
  return JSON.parse(atob(token.split(".")[1]));
};

export const getBsvalue = (value1: any, value2: any) => {
  if (value1 === "OBH" && value2 === "UNET") {
    return "search";
  } else if (!value1 || !value2) {
    return null;
  } else if (value1 === "LOB" || value2 === "PLATFORM") {
    return null;
  } else {
    return "oth";
  }
};
