import React, { useEffect, useState } from "react";
import "./TaskComponent.css";
import axios from "axios";
// import { getCookie } from "./helper";
import Pagination from "./pagination";

const TaskComponent = () => {
  const [tableData, setTableData] = useState(null);

  useEffect(() => {
    debugger;
    fetchTableData();
  }, []);

  const fetchTableData = async () => {
    try {
      axios
        .get("https://edaaiml.optum.com/CodeXchange/get_data_claimly")
        .then((response) => {
          console.log(response.data);
          setTableData(response.data);
        })
        .catch((error) => {
          //setError('Error loading data from MMI8 API');
          console.error(error);
          // Handle error cases
        });
    } catch (error) {}
  };

  const [currentPage, setCurrentPage] = React.useState(1);
  const [pageSize, setPageSize] = React.useState(10); // per page size
  const pageCount = Math.ceil(tableData?.length / pageSize);
  const handlePageOnClick = (props) => {
    let selectedPage = props.selected;
    setCurrentPage(selectedPage);
  };

  const handlePageSizeOnChange = (value) => {
    setCurrentPage(1);
    setPageSize(value);
  };
  const indexOfLastPost = currentPage * pageSize;
  const indexOfFirstPost = indexOfLastPost - pageSize;
  const benefitInfoList = tableData?.slice(indexOfFirstPost, indexOfLastPost);

  return (
    <div className="task">
      <div className="task-header">
        {/* <h2>{task.taskName}</h2>
        <p>{task.description}</p> */}
      </div>
      <div className="task-table">
        <table>
          <thead>
            <tr>
              <th>Claim Number</th>
              <th>LOB - Platform</th>
              <th>Edit ID</th>
              <th>Status</th>
              <th>Output Summary</th>
              <th>Feedback Description</th>
            </tr>
          </thead>
          <tbody>
            {
              //benefitInfoList
              // .filter(
              //   (t) => t.user_msid === JSON.parse(getCookie("userInfo"))["sub"]
              // )
              benefitInfoList?.map((task, index) => (
                <tr key={index}>
                  <td>{task?.claim_number}</td>
                  <td>
                    {task?.lob_id} - {task?.platform_id}
                  </td>
                  <td>{task?.edit_info}</td>
                  <td>
                    {task?.feedback_is_usefull !== "1"
                      ? "Negative"
                      : "Positive"}
                  </td>
                  <td>{task?.llm_output.substring(0, 200)}</td>
                  <td>{task?.feedback_description}</td>

                  {/* <td>
                  <button>Edit</button>
                  <button>Complete</button>
                  <button>Message</button>
                </td>  */}
                </tr>
              ))
            }
          </tbody>
        </table>
        {tableData?.length > 0 ? (
          <Pagination
            data={tableData}
            currentPage={currentPage}
            pageCount={pageCount}
            handlePageOnClick={handlePageOnClick}
            pageSize={pageSize}
            handlePageSizeOnChange={handlePageSizeOnChange}
          ></Pagination>
        ) : (
          <></>
        )}
      </div>
    </div>
  );
};

export default TaskComponent;
