import React from "react";
import "./TaskComponent.css";

const Pagination = ({
  data,
  currentPage,
  pageCount,
  handlePageOnClick,
  pageSize,
  handlePageSizeOnChange,
}) => {
  return (
    <>
      <div className="maindivpg">
        <label> Item per Page: </label> &nbsp;
        <select
          className="select dropdownpg"
          onChange={(e) => handlePageSizeOnChange(e.target.value)}
        >
          <option value="5">05</option>
          <option selected value="10">
            10
          </option>
          <option value="15">15</option>
          <option value="20">20</option>
          <option value="25">25</option>
          <option value="30">30</option>
        </select>
        <label className="lblpg">
          {(currentPage - 1) * pageSize + 1}-
          {currentPage * pageSize > data.length
            ? data.length
            : currentPage * pageSize}{" "}
          of {data.length}
        </label>
        <button
          onClick={() => handlePageOnClick({ selected: 1 })}
          disabled={currentPage === 1}
          className="btnpg"
        >
          <img src="/first.svg" className="imgpg" alt="" />
        </button>
        &nbsp;
        <button
          onClick={() => handlePageOnClick({ selected: currentPage - 1 })}
          disabled={currentPage === 1}
          className="btnpg"
        >
          <img src="/previous.svg" className="imgpg" alt="" />
        </button>
        &nbsp;
        <button
          onClick={() => handlePageOnClick({ selected: currentPage + 1 })}
          disabled={currentPage === pageCount}
          className="btnpg"
        >
          <img src="/nextbtn.svg" className="imgpg" alt="" />
        </button>
        &nbsp;
        <button
          onClick={() => handlePageOnClick({ selected: pageCount })}
          disabled={currentPage === pageCount}
          className="btnpg"
        >
          <img src="/last.svg" className="imgpg" alt="" />
        </button>
      </div>
    </>
  );
};

export default Pagination;
