/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import "./home.css";

export const Home = () => {
  return (
    <div>
      <div className="home-us-container">
        <div className="home-us-content">
          <h1>Welcome to Claimly AI</h1>
        </div>
      </div>
    </div>
  );
};
