import React, { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Routes, Route } from "react-router-dom";
import { AppHeader } from "../common/Header";
import { AppFooter } from "../common/Footer";
import "./App.css";
import { NoAccess } from "../components/noaccess";
import { Home } from "../components/Home";
import { AboutUs } from "../components/Aboutus";
import { SideNavigator } from "../components/SideNavigator";

import { LoaderOverLay } from "../common/LoaderOverLay";

import { getCookie } from "../components/helper";
import ProtectedRoute from "./ProtectedRoute";
import { useAuth } from "../common/AuthProvider/authentication";
import { SearchClaim } from "../components/SearchClaim";
import Dashboard from "../components/Dashboard/dashboard";

export const RoutesApp = () => {
  const navigate = useNavigate();
  const [data, setData] = React.useState(getCookie("authAccessTokenInfo"));
  const location = useLocation();
  const isAuth = useAuth();

  useEffect(() => {
    if (data === "" || data === null) {
      navigate("/login", { replace: true });
    }
  }, []);

  useEffect(() => {
    if (data !== null && location.pathname === "/") {
      navigate("/", { replace: true });
    }
  }, [data]);

  const Layout = ({ children }: { children: React.ReactNode }) => (
    <div>
      <AppHeader />
      <div className="content-wrapper">
        <div className="content-sidebar">
          <SideNavigator />
        </div>
        <div className="content-main">
          <main>{children}</main>
        </div>
      </div>
      <AppFooter />
    </div>
  );

  return isAuth ? (
    <Layout>
      <Routes>
        <Route path="/protect" element={<ProtectedRoute />} />
        <Route path="/" element={<Home />} />
        <Route path="/searchbar" element={<SearchClaim />} />
        <Route path="/noaccess" element={<NoAccess />} />
        <Route path="/aboutus" element={<AboutUs />} />
        <Route path="/dashboard" element={<Dashboard />} />
        <Route path="*" element={<Home />} />
        <Route
          path="/login"
          element={<LoaderOverLay message="Single Sign-on to OPTUM" />}
        />
      </Routes>
    </Layout>
  ) : (
    <LoaderOverLay message="Single sign-on to OPTUM" />
  );
};
