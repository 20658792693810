// src/App.js
import React from "react";
import TaskComponent from "../TaskComponent";
import Metrics from "../Metrics";
import "../TaskComponent.css";
import Carousel from "react-material-ui-carousel";

const tasks = [
  {
    taskName: "Review Claim #12345",
    description: "Check for duplicate charges",
    assignedTo: "John Doe",
    assignedDate: "2023-10-10",
    dueDate: "2023-10-15",
    assignedBy: "Jane Smith",
  },
  // Add more tasks as needed
];

const metrics = [
  { value: 120, label: "Total SOPs Worked On", type: "success" },
  { value: 95, label: "SOP Recommendation Positive", type: "info" },
  { value: 25, label: "SOP Recommendation Negative", type: "warning" },
];

function dashboard() {
  return (
    <div className="App">
      <Carousel>
        <header className="App-header-dashbaord1"></header>
        <header className="App-header-dashbaord2"></header>
        <header className="App-header-dashbaord3"></header>
        <header className="App-header-dashbaord4"></header>
      </Carousel>
      <Metrics metrics={metrics} />
      <TaskComponent tasks={tasks} />
    </div>
  );
}

export default dashboard;
