import React, { FC, useEffect, useState } from "react";
import {
  anchorProperties,
  Button,
  Header,
  Heading,
  getNativeProps,
  Avatar,
  Navigation,
  NavigationLinkProps,
  NavType,
} from "@uitk/react";
import { Link, useLocation } from "react-router-dom";
import Logo from "../../svg/Logo.svg";
import styled from "styled-components";
import { parseJwt, setCookie, getCookie } from "../../components/helper.js";
import { SideNavigator } from "../../components/SideNavigator";

//import JohnImg from "../../../../../assets/man1.png";
//const Logo = require("../../svg/Logo.svg") as string;

const App = styled.div`
  height: 100%;
  @media only screen and (min-width: 980px) {
    display: flex;
    flex-direction: column;
  }
`;

const AppContent = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  @media only screen and (min-width: 980px) {
    flex-direction: row;
  }
`;

const Main = styled.main`
  margin: 20px;
`;

const CurrentRouteWrapper = styled.div`
  padding: 12px 0;
`;

const CurrentRoute = styled.span`
  margin-left: 8px;
`;

const defaultLogo = <img src={Logo} className="uitk-header__logo" alt="logo" />;

const VerticalNavigation = styled(Navigation)`
  @media only screen and (min-width: 980px) {
    position: sticky;
    top: 0;
    height: calc(100vh - 86px);
  }
`;

const CurrentRouteContainer: FC = () => (
  <CurrentRouteWrapper>
    <CurrentRoute>{useCurrentRoute()}</CurrentRoute>
  </CurrentRouteWrapper>
);

const useCurrentRoutePath = () => {
  const { pathname: route } = useLocation();
  return route;
};

// create hook to return the current route
const useCurrentRoute = () => {
  const { pathname: route } = useLocation();
  return route;
};

// define our custom link so client side routing works
const RoutableLink: FC<NavigationLinkProps> = (item) => {
  const { children, url } = item;
  const anchorProps = getNativeProps(item, anchorProperties);
  return (
    <Link to={url} {...anchorProps}>
      {children}
    </Link>
  );
};

const verticalNavigationConfig = {
  linkAs: RoutableLink,
  links: [
    { label: "Home", url: "/" },
    { label: "Claim Search", url: "/contact" },
  ],
};

export const AppHeader = () => {
  const [userName, setuserName] = useState("");
  useEffect(() => {
    //window.location = config.APP_SSO_LINK;
    const userInfoData = JSON.parse(getCookie("userInfo"));
    if (userInfoData) {
      setuserName(userInfoData["displayName"]);
    }
  }, []);

  const globalNavLinkAvatarConfig = {
    linkAs: RoutableLink,
    links: [
      { label: "About Us", url: "/aboutus" },
      {
        label: "Claimly AI-Support",
        url: "https://teams.microsoft.com/l/channel/19%3AzET13dCmqcZC8K3s4lHWK4kkzKpvFnV7Yf_k10KJFpc1%40thread.tacv2/Claimly-AI(Demo%20Instance)?groupId=3eb2d819-6a87-479a-ae80-a71f9b4cc6ae&tenantId=db05faca-c82a-4b9d-b9c5-0f64b6755421",
        target: "_blank",
      },
      { label: "Welcome, " + userName, url: "/" },
    ],
  };
  return (
    <App>
      <Header
        logoContent={defaultLogo}
        globalNavigation={globalNavLinkAvatarConfig}
        useLocation={useCurrentRoute}
        skipLink={{ id: "main" }}
        productName="Claimly AI"
        productLink="#"
      />
      <AppContent>
        {/* <VerticalNavigation
        useLocation={useCurrentRoute}
        variant={NavType.VERTICAL}
        config={verticalNavigationConfig}
      /> */}
        {/* <Main tabIndex={-1} id="main">  
          <SideNavigator/>
        </Main> */}
      </AppContent>
    </App>
  );
};
