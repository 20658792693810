import React, { useEffect } from "react";
import { useState } from "react";
import "./SearchClaim.css";
import styled from "styled-components";
import axios from "axios";
import { getCookie } from "../helper";
import { ClaimInterface } from "../../common/interfaces/ClaimInterface";
import { Card } from "@uitk/react";
import "react-tabs/style/react-tabs.css";
import { ThumbUpIcon, ThumbDownIcon } from "@uitk/react-icons";
import ReactMarkdown from "react-markdown";
import { getBsvalue } from "../../utilities/helper/helper";
import { Bsdropdown } from "./Bsdropdown";
import Alert from "@mui/material/Alert";
import FlexBanner from "flex-banner";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

export const SearchClaim = () => {
  const claimDetails = {
    field1: 0,
    field2: "",
    field3: "",
    field4: "",
    field5: "",
    field6: "",
    field7: "",
    field8: "",
  };

  const [claimNumber, setClaimNumber] = useState("");
  const [error, setError] = useState(null);
  const [tabsVisible, setTabsVisible] = useState(false);
  const [tabList, setTabList] = useState([]);
  const [activeTab, setActiveTab] = useState("");
  const [eds1Response, setEds1Response] =
    useState<ClaimInterface>(claimDetails);
  const [eds2Response, eds2] = useState<ClaimInterface>(claimDetails);
  const [eds3Response, eds3] = useState<ClaimInterface>(claimDetails);
  const [eds8Response, eds8] = useState<ClaimInterface>(claimDetails);
  const [eds9Response, eds9] = useState<ClaimInterface>(claimDetails);
  const [edaaimlResponse, edaaiml] = useState([]);
  const [showParagraph, setShowParagraph] = useState(false);
  const [loadingRecommendNext, setLoadingRecommendNext] = useState(false);
  const [loadingParagraph] = useState(false);
  const [loading, setLoading] = useState(false);
  const [showForm, setShowForm] = useState(false);
  const [feedbackText, setfeedbackText] = useState("");
  const [feedbackOpt, setFeedbackOption] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const toggleOpen = () => setIsOpen(!isOpen);
  const [lobValue, setLobValue] = useState("");
  const [pltValue, setPltValue] = useState("");
  const [searchMessage, setSearchMessage] = useState("");
  const [searchDiseable, setSearchDiseable] = useState(true);
  const [checkMaintaince, setCheckMaintaince] = useState(false);
  const [checkRecommandNextClick, setCheckRecommandNextClick] = useState(true);
  const [claimid, setClaimId] = useState("");

  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    const checkTime = () => {
      const now = new Date();
      const startTime = new Date();
      const endTime = new Date();

      // Set your desired start and end times (24-hour format)
      startTime.setHours(5, 30, 0); // 11:00 AM
      endTime.setHours(7, 0, 0); // 01:00 PM

      // Check if current time is between start and end times
      setCheckMaintaince(now >= startTime && now <= endTime ? true : false);
    };

    checkTime();
    const interval = setInterval(checkTime, 5 * 60000); // Check every 5 minute

    return () => clearInterval(interval);
  }, []);

  const resetState = () => {
    setClaimNumber("");
    setError(null);
    setTabsVisible(false);
    setTabList([]);
    setActiveTab("");
    setEds1Response(claimDetails);
    eds2(claimDetails);
    eds3(claimDetails);
    eds8(claimDetails);
    eds9(claimDetails);
    edaaiml([]);
    setShowParagraph(false);
    setLoadingRecommendNext(false);
    setLoading(false);
    setShowForm(false);
    setfeedbackText("");
    setFeedbackOption("");
    setShowModal(false);
    setLobValue("");
    setPltValue("");
    setSearchMessage("");
    setSearchDiseable(true);
  };

  const lob = [
    { value: "LOB", label: "LOB" },
    { value: "E&I", label: "E&I" },
    { value: "C&S", label: "C&S" },
    { value: "M&R", label: "M&R" },
    { value: "OBH", label: "OBH" },
  ];

  const plateform = [
    { value: "PLATFORM", label: "PLATFORM" },
    { value: "UNET", label: "UNET" },
    { value: "USP", label: "USP" },
    { value: "COSMOS", label: "COSMOS" },
    { value: "NICE", label: "NICE" },
    { value: "CSP Facets", label: "CSP Facets" },
  ];

  const StoryWrapper = styled.div`
    background-color: ${(props) => props.theme.UitkSemanticColorBgCanvas};
    padding: 20px 20px 20px 20px;
    .uitk-button {
      margin-left: 10px;
      margin-top: 10px;
    }
  `;

  useEffect(() => {
    if (tabList.length > 0) {
      setActiveTab(tabList[0].id);
    }
  }, [tabList]);

  useEffect(() => {
    setShowParagraph(false);
    setActiveTab(activeTab);
    setCheckRecommandNextClick(true);
  }, [activeTab]);

  const handleClosePopover = () => {
    setShowModal(false);
  };

  const handleButtonClick = () => {
    setShowModal((prevShowModal) => !prevShowModal);
    setShowForm(false);
    setFeedbackOption("");
    setfeedbackText("");
  };

  const renderTabContent = () => {
    console.log("Active Tab", activeTab);
    const jsonString = JSON.stringify(edaaimlResponse)
      .replace(/\\n/g, "\n")
      .replace(/^{"|"}$/g, "")
      .replace(/":"/g, " : ")
      .replace(/\\/g, "");
    // Check if the active tab is not listed in the specified tab titles
    const activeTabTitle = tabList.find((tab) => tab.id === activeTab)?.title;
    const modifiedActiveTabTitle =
      activeTabTitle?.substring(0, 1) + activeTabTitle?.substring(2);
    const isTabValid = [
      "E2570",
      "E2551",
      "W2696",
      "W2697",
      "E1434",
      "W00851",
      "W0999",
      "W1000",
      "W2399",
      "W2250",
      "W01444",
      "W02398",
      "W01445",
      "E0840",
      "E1535",
      "E0348",
      "W0180",
      "E2047",
      "W2989",
      "E2916",
      "W0353",
      "E2193",
      "E1418",
      "E2561",
      "E2491",
      "W2173",
      "W2711",
      "W2169",
      "E2235",
      "W0741",
      "W0077",
      "E1424",
      "E0191",
      "E2001",
      "E2637",
      "E1279",
      "E0572",
      "E2493",
    ].includes(modifiedActiveTabTitle);
    console.log("Is Tab Valid", isTabValid);
    return (
      <>
        <div className="search-result-container">
          {tabList.map(
            (tab) =>
              activeTab === tab.id && (
                //<div key={tab.id} className="response">
                <Card>
                  <div className="edit-container">
                    <div className="response">
                      <h3 className="header">UNET-EDS1</h3>
                      <div>
                        <p className="col col-3">
                          Billed Amount:{" "}
                          <span style={{ color: "black", fontFamily: "Arial" }}>
                            ${eds1Response.field1}
                          </span>
                        </p>
                        <p className="col col-3">
                          CPT/HCPC:{" "}
                          <span style={{ color: "black", fontFamily: "Arial" }}>
                            {eds1Response.field2}
                          </span>
                        </p>
                        <p className="col col-3">
                          REV:{" "}
                          <span style={{ color: "black", fontFamily: "Arial" }}>
                            {eds1Response.field3}
                          </span>
                        </p>
                        <p className="col col-3">
                          Policy:{" "}
                          <span style={{ color: "black", fontFamily: "Arial" }}>
                            {eds1Response.field4}
                          </span>
                        </p>
                        <p className="col col-3">
                          DX:{" "}
                          <span style={{ color: "black", fontFamily: "Arial" }}>
                            {eds1Response.field5}
                          </span>
                        </p>
                      </div>
                    </div>
                    <div className="response">
                      <h3 className="header">UNET-EDS2</h3>
                      <div>
                        <p className="col col-3">
                          Member State:{" "}
                          <span style={{ color: "black", fontFamily: "Arial" }}>
                            {eds2Response.field1}
                          </span>
                        </p>
                      </div>
                    </div>
                    <div className="response">
                      <h3 className="header">UNET-EDS3</h3>
                      <div>
                        <p className="col col-3">
                          Provider State:{" "}
                          <span style={{ color: "black", fontFamily: "Arial" }}>
                            {eds3Response.field1}
                          </span>
                        </p>
                        <p className="col col-3">
                          TIN:{" "}
                          <span style={{ color: "black", fontFamily: "Arial" }}>
                            {eds3Response.field2}
                          </span>
                        </p>
                      </div>
                    </div>
                    <div className="response">
                      <h3 className="header">UNET-EDS8</h3>
                      <div>
                        <p className="col col-3">
                          Provider Group:{" "}
                          <span style={{ color: "black", fontFamily: "Arial" }}>
                            {eds8Response.field1}
                          </span>
                        </p>
                        <p className="col col-3">
                          Provider Type:{" "}
                          <span style={{ color: "black", fontFamily: "Arial" }}>
                            {eds8Response.field2}
                          </span>
                        </p>
                        <p className="col col-3">
                          TAX ID:{" "}
                          <span style={{ color: "black", fontFamily: "Arial" }}>
                            {eds8Response.field3}
                          </span>
                        </p>
                        <p className="col col-3">
                          Provider State:{" "}
                          <span style={{ color: "black", fontFamily: "Arial" }}>
                            {eds8Response.field4}
                          </span>
                        </p>
                      </div>
                    </div>
                    <div className="response">
                      <h3 className="header">UNET-EDS9</h3>
                      <div>
                        <p className="col col-3">
                          Policy:{" "}
                          <span style={{ color: "black", fontFamily: "Arial" }}>
                            {eds9Response.field1}
                          </span>
                        </p>
                      </div>
                    </div>
                  </div>
                </Card>
              )
          )}
          <div className="nba-container">
            {activeTab && (
              <button
                className={`search-button_rc ${
                  loadingRecommendNext ? "disabled" : ""
                }`}
                onClick={AI_Model}
                disabled={loadingRecommendNext || loadingParagraph}
              >
                {loadingRecommendNext || loadingParagraph
                  ? "Please wait..."
                  : "Recommend Next →"}
              </button>
            )}
            {showParagraph && activeTab && (
              <div className="content-container nba-content">
                <div className="custom-paragraph">
                  <h3 className="header_rc">Next Best Action:</h3>
                  {activeTab && (
                    <ReactMarkdown
                      children={
                        isTabValid
                          ? jsonString
                          : "*At the moment, the edit is not within the scope. However, we are actively working on incorporating it in our future updates."
                      }
                      className="react-markdown-test"
                    />
                  )}
                </div>
              </div>
            )}
          </div>
          <div>
            <button
              className="feedbackBtn1"
              disabled={checkRecommandNextClick}
              onClick={handleButtonClick}
            >
              Submit Feedback
            </button>

            {showModal && (
              <div id="feedbackPopover" className="popover">
                <h4>Feedback</h4>{" "}
                <button id="closePopover" onClick={handleClosePopover}>
                  &#x2715;
                </button>
                <div>
                  <button
                    onClick={() => setFeedbackOption("Like")}
                    style={{ backgroundColor: "transparent" }}
                  >
                    <ThumbUpIcon
                      style={{
                        stroke: "gray",
                        color: feedbackOpt === "Like" ? "green" : "transparent",
                      }}
                    />
                  </button>
                  <button
                    onClick={() => setFeedbackOption("Dislike")}
                    style={{ backgroundColor: "transparent" }}
                  >
                    <ThumbDownIcon
                      style={{
                        stroke: "gray",
                        color:
                          feedbackOpt === "Dislike" ? "red" : "transparent",
                      }}
                    />
                  </button>
                  {/* <label>Selected option: {feedbackOpt}</label> */}
                  {/* <br /> */}
                  <textarea
                    id="feedbackText"
                    className="feedback-text"
                    placeholder="Enter your feedback"
                    value={feedbackText}
                    onChange={(e) => setfeedbackText(e.target.value)}
                  ></textarea>
                  <br />
                  <button
                    style={{ backgroundColor: "#ff612b", color: "#fff" }}
                    id="submitFeedback"
                    onClick={handleClickOpen}
                  >
                    Submit
                  </button>
                </div>
              </div>
            )}

            <Dialog
              open={open}
              onClose={handleClose}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
            >
              <DialogTitle id="alert-dialog-title">
                Are you sure you want to submit the feedback?
              </DialogTitle>
              {/* <DialogContent>
                      <DialogContentText id="alert-dialog-description">
                        Are you sure you want to submit the feedback?
                      </DialogContentText>
                    </DialogContent> */}
              <DialogActions>
                <Button onClick={handleSubmitFeedback}>Yes</Button>
                <Button onClick={handleClose} autoFocus>
                  No
                </Button>
              </DialogActions>
            </Dialog>
            <ToastContainer />
          </div>
        </div>
      </>
    );
  };
  const AI_Model = async () => {
    setLoadingRecommendNext(true);
    setShowParagraph(false);
    try {
      const activeTabTitle = tabList.find((tab) => tab.id === activeTab)?.title;
      const modifiedActiveTabTitle =
        activeTabTitle?.substring(0, 1) + activeTabTitle?.substring(2);
      const response = await axios.post(
        "https://edaaiml.optum.com:8443/edaaiml",
        {
          claimNumber: claimNumber,
          //edit: activeTab
          activeTabTitle: modifiedActiveTabTitle,
        }
      );
      console.log(response.data);
      edaaiml(response.data);
      setShowParagraph(true);
      var llm = JSON.stringify(response.data)
        .replace(/\\n/g, "\n")
        .replace(/^{"|"}$/g, "")
        .replace(/":"/g, " : ")
        .replace(/\\/g, "");

      //call save api

      const saveresponse = await axios.post(
        "https://edaaiml.optum.com/CodeXchange/insert_data_claimly",
        {
          claim_number: claimNumber,
          edit_info: activeTabTitle,
          llm_output: llm?.includes("response : 'href'")
            ? "*At the moment, the edit is not within the scope. However, we are actively working on incorporating it in our future updates."
            : llm,
          lob_id: lobValue,
          platform_id: pltValue,
          user_msid: JSON.parse(getCookie("userInfo"))["sub"],
          feedback_is_usefull: "1",
        }
      );
      if (saveresponse.status === 200) {
        setCheckRecommandNextClick(false);
        setClaimId(saveresponse.data.id);
      } else {
        setCheckRecommandNextClick(true);
        setClaimId("");
      }

      console.log(saveresponse);
    } catch (error) {
      console.error(error);
      // Handle error cases
    } finally {
      setLoadingRecommendNext(false);
    }
  };

  const handleSearch = () => {
    setLoading(true);
    setShowParagraph(false);
    setError(null);
    if (claimNumber.trim().length >= 9) {
      setTabsVisible(false);
      axios
        .post("https://edaaiml.optum.com:8443/get-token", {
          claimNumber: claimNumber,
        })
        .then((response) => {
          console.log(response.data);
          axios
            .post("https://edaaiml.optum.com:8443/gap2", {
              claimNumber: claimNumber,
            })
            .then((response) => {
              console.log(response.data);
              axios
                .post("https://edaaiml.optum.com:8443/gts3", {
                  claimNumber: claimNumber,
                })
                .then((response) => {
                  console.log(response.data);
                  axios
                    .post("https://edaaiml.optum.com:8443/eds1", {
                      claimNumber: claimNumber,
                    })
                    .then((response) => {
                      console.log(response.data);
                      setEds1Response(response.data);
                      axios
                        .post("https://edaaiml.optum.com:8443/mpc", {
                          claimNumber: claimNumber,
                        })
                        .then((response) => {
                          console.log(response.data);
                          const tabList = Object.keys(response.data).map(
                            (key) => ({
                              id: key,
                              title: response.data[key],
                            })
                          );
                          setTabList(tabList);

                          setLoading(false);
                          setTabsVisible(true);
                          axios
                            .post("https://edaaiml.optum.com:8443/cei", {
                              claimNumber: claimNumber,
                            })
                            .then((response) => {
                              console.log(response.data);
                              axios
                                .post("https://edaaiml.optum.com:8443/cei", {
                                  claimNumber: claimNumber,
                                })
                                .then((response) => {
                                  console.log(response.data);
                                  axios
                                    .get(
                                      "https://edaaiml.optum.com:8443/mmi1?claimNumber=" +
                                        claimNumber
                                    )
                                    .then((response) => {
                                      console.log(response.data);
                                    })
                                    .catch((error) => {
                                      setLoading(false);
                                      //setError('Error loading data from MMI1 API');
                                      console.error(error);
                                    });
                                  axios
                                    .get(
                                      "https://edaaiml.optum.com:8443/mmi8?claimNumber=" +
                                        claimNumber
                                    )
                                    .then((response) => {
                                      console.log(response.data);
                                    })
                                    .catch((error) => {
                                      setLoading(false);
                                      //setError('Error loading data from MMI8 API');
                                      console.error(error);
                                      // Handle error cases
                                    });
                                })
                                .catch((error) => {
                                  setLoading(false);
                                  //setError('Error loading data from MXI API');
                                  console.error(error);
                                  // Handle error cases
                                });
                            })
                            .catch((error) => {
                              setLoading(false);
                              //setError('Error loading data from CEI API');
                              console.error(error);
                              // Handle error cases
                            });
                          axios
                            .post("https://edaaiml.optum.com:8443/ari", {
                              claimNumber: claimNumber,
                            })
                            .then((response) => {
                              console.log(response.data);
                            })
                            .catch((error) => {
                              setLoading(false);
                              //setError('Error loading data from ARI API');
                              console.error(error);
                              // Handle error cases
                            });
                          axios
                            .post("https://edaaiml.optum.com:8443/pmi", {
                              claimNumber: claimNumber,
                            })
                            .then((response) => {
                              console.log(response.data);
                            })
                            .catch((error) => {
                              setLoading(false);
                              //setError('Error loading data from PMI API');
                              console.error(error);
                              // Handle error cases
                            });
                          axios
                            .post("https://edaaiml.optum.com:8443/mri", {
                              claimNumber: claimNumber,
                            })
                            .then((response) => {
                              console.log(response.data);
                            })
                            .catch((error) => {
                              setLoading(false);
                              //setError('Error loading data from MRI API');
                              console.error(error);
                              // Handle error cases
                            });
                        })
                        .catch((error) => {
                          setLoading(false);
                          //setError("Error loading data from MPC API");
                          setError("No Record Found");
                          console.error(error);
                          // Handle error cases
                        });
                    })
                    .catch((error) => {
                      setLoading(false);
                      //setError("Error loading data from EDS1 API");
                      setError("No Record Found");
                      console.error(error);
                      // Handle error cases
                    });
                })
                .catch((error) => {
                  setLoading(false);
                  //setError("Error loading data from GTS3 API");
                  setError("No Record Found");
                  console.error(error);
                  // Handle error cases
                });
            })
            .catch((error) => {
              setLoading(false);
              setError("Error loading data from GAP2 API");
              //console.error(error);
              // Handle error cases
            });
          axios
            .post("https://edaaiml.optum.com:8443/eds2", {
              claimNumber: claimNumber,
            })
            .then((response) => {
              console.log(response.data);
              eds2(response.data);
            })
            .catch((error) => {
              setLoading(false);
              //setError("Error loading data from EDS2 API");
              setError("No Record Found");
              console.error(error);
              // Handle error cases
            });
          axios
            .get(
              "https://edaaiml.optum.com:8443/eds3?claimNumber=" + claimNumber
            )
            .then((response) => {
              console.log(response.data);
              eds3(response.data);
            })
            .catch((error) => {
              setLoading(false);
              //setError("Error loading data from EDS3 API");
              setError("No Record Found");
              console.error(error);
              // Handle error cases
            });
          axios
            .post("https://edaaiml.optum.com:8443/eds8", {
              claimNumber: claimNumber,
            })
            .then((response) => {
              console.log(response.data);
              eds8(response.data);
            })
            .catch((error) => {
              setLoading(false);
              //setError("Error loading data from EDS8 API");
              setError("No Record Found");
              console.error(error);
              // Handle error cases
            });
          axios
            .post("https://edaaiml.optum.com:8443/eds9", {
              claimNumber: claimNumber,
            })
            .then((response) => {
              console.log(response.data);
              eds9(response.data);
            })
            .catch((error) => {
              setLoading(false);
              //setError("Error loading data from EDS9 API");
              setError("No Record Found");
              console.error(error);
              // Handle error cases
            });
          axios
            .post("https://edaaiml.optum.com:8443/mhi", {
              claimNumber: claimNumber,
            })
            .then((response) => {
              console.log(response.data);
            })
            .catch((error) => {
              console.error(error);
              // Handle error cases
            });
          axios
            .post("https://edaaiml.optum.com:8443/rhi", {
              claimNumber: claimNumber,
            })
            .then((response) => {
              console.log(response.data);
            })
            .catch((error) => {
              console.error(error);
              // Handle error cases
            });
        })
        .catch((error) => {
          console.error(error);
          // Handle error cases
        });
      fetch("https://edaaiml.optum.com:8443/api/endpoint", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ claimNumber }),
      })
        .then((response) => response.json())
        .then((data) => {
          console.log(data);
        })
        .catch((error) => {
          console.error("Error:", error);
        });
    } else {
      setTabsVisible(false);
      setLoading(false);
      alert("Please enter a valid Claim Number");
      console.log("Please enter a valid Claim Number");
    }
  };

  const handleSubmitFeedback = (e: React.FormEvent) => {
    e.preventDefault();

    const timestamp = new Date().toLocaleString();
    const userId =
      getCookie("userInfo") !== "" && getCookie("userInfo") !== null
        ? JSON.parse(getCookie("userInfo"))["sub"]
        : "";
    const ClaimNumber = claimNumber;
    const comment = feedbackText;
    const Feedback = feedbackOpt;
    const activeTabTitle = tabList.find((tab) => tab.id === activeTab)?.title;

    // Send the feedback data to the server
    fetch(
      "https://edaaiml.optum.com/CodeXchange/update_feedback_data_claimly",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          feedback_is_usefull: Feedback === "Like" ? "1" : "0",
          feedback_description: comment,
          id: claimid,
        }),
      }
    )
      .then((response) => {
        console.log(response);
        if (response.status === 200) {
          debugger;
          setOpen(false);
          setClaimId("");
          // Handle the successful submission

          console.log("Feedback submitted successfully");
          setShowModal(false);
          setShowForm(false);
          toast.success("Feedback submitted successfully.", {
            autoClose: 3000,
            position: "top-right",
          });
        } else {
          // Handle any errors
          console.log(response);

          console.log("Error submitting feedback");
          setShowModal(false);
          setShowForm(false);
          toast.error("Error submitting feedback.", {
            autoClose: 3000,
            position: "top-right",
          });
        }
      })
      .catch((error) => {
        setShowForm(false);
        setShowModal(false);
        console.log("Error submitting feedback:", error);
        toast.error("Error submitting feedback.", {
          autoClose: 3000,
          position: "top-right",
        });
      });
  };

  const handleKeyPress = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === "Enter") {
      handleSearch();
    }
  };

  const handleOnChange = (event: any, id: string) => {
    let bsVal;
    if (id === "lob") {
      setLobValue(event.target.value);
      bsVal = getBsvalue(event.target.value, pltValue);
    } else if (id === "plt") {
      setPltValue(event.target.value);
      bsVal = getBsvalue(lobValue, event.target.value);
    }
    if (bsVal === "search") {
      setSearchMessage("Enter Claim Number");
      setSearchDiseable(false);
    } else if (!bsVal) {
      setSearchMessage("Feature coming soon...");
      setSearchDiseable(true);
    } else if (bsVal === "oth") {
      setSearchMessage("Feature coming soon...");
      setSearchDiseable(true);
      // resetState();
    }
  };

  return (
    <div>
      {checkMaintaince ? (
        <FlexBanner
          title="Sorry for the inconvenience but we&rsquo;re performing some maintenance at the moment(11am IST - 1:00pm IST)."
          ctaLink=""
          ctaTitle=""
          isCenter={true}
          crossIconSize={0}
          wrapperStyle={{ backgroundColor: "red" }}
          mainStyleTitle={{ color: "black" }}
          mainStyleLink={{ color: "red" }}
          crossStyle={{ color: "black" }}
        />
      ) : (
        <div className="dropdown-container">
          <div className="col-md-4">
            <Bsdropdown
              label="Select Lob"
              value={lobValue}
              options={lob}
              onChange={(event: React.ChangeEvent<HTMLSelectElement>) =>
                handleOnChange(event, "lob")
              }
            />
          </div>
          <div className="col-md-4">
            <Bsdropdown
              label="Select Platform"
              value={pltValue}
              options={plateform}
              onChange={(event: React.ChangeEvent<HTMLSelectElement>) =>
                handleOnChange(event, "plt")
              }
            />
          </div>
          <div className="col-md-4">
            <div className="search-bar-container">
              <input
                className="search_input"
                type="text"
                placeholder={searchMessage}
                value={claimNumber}
                onChange={(e) => setClaimNumber(e.target.value)}
                onKeyPress={handleKeyPress}
                disabled={searchDiseable}
              />
              <button
                className="search-button"
                onClick={handleSearch}
                disabled={searchDiseable}
              >
                Search
              </button>
            </div>
          </div>
        </div>
      )}

      {loading ? (
        <div className="loading-container">
          <div className="loader"></div>
          <p>Loading...</p>
        </div>
      ) : (
        <>
          {error && <Alert severity="error">{error}</Alert>}
          {tabsVisible && (
            <div className="tabs-container">
              <div className="tabs-scrollable">
                <ul className="tabs">
                  {tabList.map((tab, index) => (
                    <li
                      key={tab.id}
                      className={
                        activeTab === tab.id ||
                        (index === 0 && activeTab === "")
                          ? "active"
                          : ""
                      }
                      onClick={() => setActiveTab(tab.id)}
                    >
                      {tab.title}
                    </li>
                  ))}
                </ul>
              </div>
              <div className="tab-content">{renderTabContent()}</div>
            </div>
          )}
        </>
      )}
    </div>
  );
};
