import React, { useState, useEffect } from "react";
import "./TaskComponent.css";
import ThumbUpIcon from "@mui/icons-material/ThumbUp";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import ThumbDownIcon from "@mui/icons-material/ThumbDown";
import axios from "axios";
const Metrics = ({ metrics }) => {
  const [metricData, setMetricData] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    debugger;
    fetchTableData();
  }, []);

  const fetchTableData = async () => {
    try {
      axios
        .get(
          "https://edaaiml.optum.com/CodeXchange/get_data_with_count_claimly"
        )
        .then((response) => {
          setMetricData(response.data);
          console.log(metricData);
        })
        .catch((error) => {
          setLoading(false);
          //setError('Error loading data from MMI8 API');
          console.error(error);
          // Handle error cases
        });
    } catch (error) {}
  };
  return (
    <div className="metrics-container">
      {metrics.map((metric, index) => (
        <div key={index} className={`metric ${metric.type}`}>
          <div style={{ padding: "20px" }}>
            {metric.type === "success" ? (
              <CheckCircleIcon color="success" />
            ) : metric.type === "info" ? (
              <ThumbUpIcon color="primary" />
            ) : (
              <ThumbDownIcon sx={{ color: "red" }} />
            )}
          </div>
          <div>
            <div className="metric-value">
              {metric.type == "info"
                ? metricData?.count_true
                : metric.type == "success"
                ? metricData?.total_rows
                : metricData?.count_false}
            </div>
            <div className="metric-label">{metric.label}</div>
          </div>
        </div>
      ))}
    </div>
  );
};

export default Metrics;
