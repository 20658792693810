export const config = {
  //local
  // API_URL: "http://localhost:3002/",
  // APP_SSO_LINK:
  //   "https://authgateway1-dev.entiam.uhg.com/as/authorization.oauth2?response_type=code&client_id=Reg1Dev_simplify_dev&redirect_uri=http://localhost:3002/&acr_values=R1_AAL1_MS-AD-Kerberos&scope=openid%20profile%20address%20email%20phone",
  // APP_BASEURL: "http://localhost:3002/",
  // client_secret: "9gxU>&bTa<YX3Qgf2ZRBN@&Spz@#gpD2&qWdr8Q9J7U@",
  // user_info_uri: "https://authgateway1-dev.entiam.uhg.com/idp/userinfo.openid",
  // Token_Api_URL: "https://authgateway1-dev.entiam.uhg.com/as/token.oauth2",
  // client_id: "Reg1Dev_simplify_dev",
  // dev
  API_URL: "https://claimly-ai-demo.optum.com/",
  APP_SSO_LINK:
    "https://authgateway1-dev.entiam.uhg.com/as/authorization.oauth2?response_type=code&client_id=Reg1Dev_claimly&redirect_uri=https://claimly-ai-demo.optum.com/&acr_values=R1_AAL1_MS-AD-Kerberos&scope=openid%20profile%20address%20email%20phone",
  APP_BASEURL: "https://claimly-ai-demo.optum.com/",
  client_secret: "wbb6rzYi>7CyR<bmowHrbW4NTdnr7bKdRSKMeL4X3w4e",
  user_info_uri: "https://authgateway1-dev.entiam.uhg.com/idp/userinfo.openid",
  Token_Api_URL: "https://authgateway3-dev.entiam.uhg.com/as/token.oauth2",
  client_id: "Reg1Dev_claimly",
};
