import React from "react";
import "./AboutUs.css";

export const AboutUs = () => {
  return (
    <>
      <div className="about-us-container">
        <div className="about-us-content">
          <h1>Claimly AI</h1>
          <div className="middle-centre">
            {" "}
            Our platform offers dynamic real-time data aggregation, interactive
            guidance, automated workflow optimization, and intelligent data
            retrieval, all aimed at enhancing decision-making accuracy and
            reducing error rates.With Claimly, different lines of business can
            experience improved efficiency, accelerated onboarding, enhanced
            accuracy, and continuous process improvement, making claims
            processing faster, more precise, and adaptable to industry demands.
            Trust Claimly, your partner in building the future of efficient and
            accurate claims processing.
          </div>
        </div>
      </div>
    </>
  );
};
